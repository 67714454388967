.container {
  font-size: calc(6em / var(--string-count));

  button {
    position: relative;
    z-index: 1;
  }
}

.strings {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  top: -0.125em;
  right: calc((100% / (var(--fret-count) + 2)) - 0.045em);
  bottom: 0.2em;
  left: calc((100% / (var(--fret-count) + 2)) - 0.045em);

  .string {
    border-top: 0.3em solid transparent;
    height: 1.5em;

    &.highlight {
      border-top-color: rgb(129, 140, 248);
    }
  }
}

.fret {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex: 1;
  position: relative;

  &.rightHanded {
    .fretString {
      border-right-width: 0.15em;
    }
  }

  &.leftHanded {
    .fretString {
      border-left-width: 0.15em;
    }
  }

  .fretString {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;
    position: relative;
    border-color: rgb(156, 163, 175);
    border-style: solid;
    border-top-width: 0.15em;
    height: 1.5em;

    &:last-child {
      border-right-width: 0;
      border-bottom-width: 0;
      border-left-width: 0;
      padding-right: 0.15em;
    }

    .stringTuning {
      position: absolute;
      top: -0.65em;
      color: rgb(156, 163, 175);
    }

    .fretFingeringContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: -0.85em;
      left: 0.0125em;
      border-radius: 100%;

      @media (min-width: 455px) {
        width: 30px;
        height: 30px;
      }

      .fretFingering {
        position: relative;
        background-color: rgb(129, 140, 248);
        width: calc((var(--string-count) / 6) * 1em);
        height: calc((var(--string-count) / 6) * 1em);
        border-radius: 100%;
        transition: all 150ms ease-in;
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(.showFingering) {
          opacity: 0!important;
        }

        & > * {
          font-size: calc((var(--string-count) / 6) * 0.7em);
        }
      }
    }
  }

  &:first-child {
    .fretString {
      padding-top: 0.15em;
      border: 0;
    }
  }

  &:nth-child(2) {
    flex: 1.2;

    &.rightHanded {
      .fretString {
        border-left-width: 0.3em;

        &:last-child {
          border-left-width: 0;
          padding-left: 0.3em;
        }
      }
    }

    &.leftHanded {
      .fretString {
        border-right-width: 0.3em;

        &:last-child {
          border-right-width: 0;
          padding-right: 0.3em;
        }
      }
    }
  }
}

.dotContainer {
  position: absolute;
  top: -1.5em;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.rightHanded {
    left: -0.13em;
  }

  &.leftHanded {
    left: 0.13em;
  }

  .dot {
    background-color: rgba(210, 213, 219);
    width: calc((var(--string-count) / 6) * 0.75em);
    height: calc((var(--string-count) / 6) * 0.75em);
    border-radius: 100%;
    margin: 1.3em;
    opacity: var(--dot-opacity, 0.35);
    transition: opacity 150ms ease-in;
  }
}
