.countInIndicator {
  --bpm: 120;
  position: absolute;
  width: 0.25em;
  height: 0.25em;
  border-radius: 100%;
  animation: spin calc(60s / var(--bpm)) ease-in infinite;
  will-change: transform;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) translateY(-0.95em);
  }
  50% {
    transform: rotate(0deg) translateY(-0.95em);
  }
  100% {
    transform: rotate(360deg) translateY(-0.95em);
  }
}
