@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark;
}

@layer base {
  body {
    @apply bg-gray-900 text-gray-400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  strong {
    @apply text-gray-300;
  }

  button,
  textarea,
  input,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  a:not([type='button']) {
    @apply font-semibold text-indigo-400 transition hover:underline;
  }

  button,
  *[type='button'] {
    appearance: none;
  }
}

@layer components {
  button.btn-normal,
  *[type='button'].btn-normal {
    @apply inline-flex items-center justify-center rounded-md bg-indigo-700 py-2 pl-3 pr-4 text-sm font-medium text-gray-300 transition hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800;
  }

  button.btn-round,
  *[type='button'].btn-round {
    @apply inline-flex items-center justify-center rounded-full border border-transparent bg-gray-500 text-white transition focus:outline-none disabled:opacity-50;
    -webkit-mask-image: radial-gradient(white, black);
  }

  button.btn-text-only,
  *[type='button'].btn-text-only {
    @apply inline-flex items-center justify-center border border-transparent transition hover:text-white focus:outline-none;
  }
}

@layer utilities {
  .text-auto-size {
    font-size: clamp(
      0.8rem,
      calc(1rem + (1.45 - 0.8) * ((100vmin - 20rem) / (96 - 20))),
      1.45rem
    );
  }

  .user-select-none {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .copy-paste-only {
    display: inline-block;
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
}
